import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNode } from 'primeng/api';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { data, cols } from './dummy-data';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  ComputeEnvironmentsListComponent,
  CredentialsListComponent,
  FilebrowserComponent,
} from '@biomodal-webapps/ui-components';
import {
  AltoCompanyService,
  WorkspaceService,
  CredentialsService,
  ComputeEnvService,
  WorkflowService,
} from '@biomodal-webapps/alto-service';
import { RouterModule } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';
import { HttpEventType } from '@angular/common/http';


@Component({
  selector: 'biomodal-webapps-step-select-files-tree',
  standalone: true,
  imports: [
    CommonModule,
    ImageHeroComponent,
    TableModule,
    MessagesModule,
    ButtonModule,
    CardModule,
    TreeTableModule,
    RouterModule,
    ComputeEnvironmentsListComponent,
    TooltipModule,
    DialogModule,
  ],
  templateUrl: './step-select-files-tree.component.html',
  styleUrls: ['./step-select-files-tree.component.css'],
})
export class StepSelectFilesTreeComponent {
  data: TreeNode[] = [];
  cols: any[] = [];
  selectedFile: TreeNode | TreeNode[] | null = null;

  destroy$: Subject<boolean> = new Subject<boolean>();
  companyId: string | null = null;
  workspace: any | null = null;
  cloudDefId: number | null = null;

  computeId: string | null = null;
  selectedBucket: TreeNode | null = null;

  projectFolderPath: string | null = null;
  metaSheetName: string | null = null;

  showCredentialsForm = false;
  messages: any[] = [];
  validationMessages: any[] = [];

  snippetDialogVisible: boolean = false;
  windowsSnippet: string = '';
  macSnippet: string = '';
  optionalMessage: string = '';

  constructor(
    private altoCompanyService: AltoCompanyService,
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService,
    private computeEnvService: ComputeEnvService,
    private workflowService: WorkflowService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.data = data;
    this.cols = cols;

    this.companyId = this.altoCompanyService.getCurrentCompany().id;
    this.workspace = this.workspaceService.getCurrentWorkspace();

    this.credentialsService.currentCredentials$
      .pipe(
        takeUntil(this.destroy$),
        filter((credentials) => credentials !== null)
      )
      .subscribe((credentials) => {
        console.log('DuetLaunchSelectFilesComponent: Received credentials', credentials);
        this.cloudDefId = credentials.id;
        let current_compute = this.computeEnvService.getCurrentCompute();

        if (current_compute) {
          if (!credentials.compute_environments.some((env: any) => env.id === current_compute!.id!)) {
            this.computeEnvService.selectComputeEnv(null);
          }
        }
        console.log(
          'DuetLaunchSelectFilesComponent: ids',
          this.companyId,
          this.workspace.id,
          this.cloudDefId
        );
      });

    this.computeEnvService.currentComputeEnv$
      .pipe(
        takeUntil(this.destroy$),
        filter((compute) => compute !== null)
      )
      .subscribe((compute) => {
        console.log('DuetLaunchSelectFilesComponent: Received compute', compute);
        this.computeId = compute!.id;
        this.checkRegionMismatch();

        console.log(
          'DuetLaunchSelectFilesComponent: ids',
          this.companyId,
          this.workspace.id,
          this.cloudDefId,
          this.computeId
        );
      });

    if (this.workspace && this.workspace.cloud_definitions) {
      this.data = this.workspace.cloud_definitions.map((definition: any) => ({
        data: {
          name: definition.name,
          type: 'Cloud Project',
        },
        children: [
          {
            data: {
              name: 'Loading...',
              type: 'loading',
            },
          },
        ],
      }));
    }
    this.setValidationMessages();
  }

  setValidationMessages() {
    this.validationMessages = [
      {
        severity: 'info',
        summary: 'Requirements',
        detail:
          'To proceed, ensure the following: A CSV metasheet is selected, a compute environment is selected, and the metasheet is in the same folder/bucket as the "nf-input" folder.',
      },
    ];
  }

  checkRegionMismatch() {
    console.log('Checking region mismatch...');
    let compute = this.computeEnvService.getCurrentCompute();
    if (compute) {
      console.log('Compute:', compute);
      if (this.selectedBucket) {
        console.log('Bucket:', this.selectedBucket);
        ///confirm that lower case selectedBucket.data.location is the same as compute.region
        if (this.selectedBucket.data.location.toLowerCase() !== compute!.region.toLowerCase()) {
          console.log('Region mismatch');
          this.messages = [
            {
              severity: 'warn',
              summary: 'Region Mismatch',
              detail: `The selected bucket is in region ${this.selectedBucket.data.location.toLowerCase()} but the selected compute environment is in region ${
                compute.region
              }. This could result in high data transfer costs.`,
            },
          ];
        } else {
          console.log('Region match');
          this.messages = [];
        }
      } else {
        console.log('No bucket selected');
      }
    } else {
      console.log('No compute selected');
    }
  }

  resetSelections() {
    this.selectedFile = null;
    this.workflowService.metaSheetName = null;
    this.workflowService.projectFolderPath = null;
    this.workflowService.selectedBucket = null;
    this.computeEnvService.selectComputeEnv(null);
    this.computeId = null;
  }

  onCloudDefinitionSelected(cloudDef: any) {
    this.credentialsService.selectCredentials(cloudDef);
  }

  validSelection(): boolean {
    const isValid =
      this.cloudDefId &&
      this.computeId &&
      this.workflowService.projectFolderPath &&
      this.workflowService.metaSheetName;

    if (isValid) {
      this.validationMessages = [];
    } else {
      this.setValidationMessages();
    }

    return !!isValid;
  }

  isDownloadableFile(): boolean {
    if (!this.selectedFile || Array.isArray(this.selectedFile)) {
      return false; // No file selected or multiple selection
    }

    const fileNode = this.selectedFile.data;

    // Only enable download for files (not folders) and supported file extensions
    const isFile = fileNode.type === 'file';
    const isSupported = this.isSupportedFile(fileNode.name);
    return isFile && isSupported;
  }

  isSupportedFile(fileName: string): boolean {
    const supportedExtensions = ['csv', 'txt', 'json', 'pdf', 'gz', 'fasta']; // Add more extensions as needed
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return supportedExtensions.includes(fileExtension || '');
  }

  downloadFile(): void {
    if (!this.selectedFile || Array.isArray(this.selectedFile)) {
      console.error('No file selected or multiple files selected.');
      return;
    }

    const file = this.selectedFile.data;

    if (!file || file.type !== 'file') {
      console.error('Selected node is not a file.');
      return;
    }

    // Use the necessary component data to get parameters for download
    const companyId = this.companyId!;
    const workspaceId = this.workspace.id;
    const cloudDefId = this.cloudDefId!;
    const bucketName = this.selectedBucket!.data.name;
    const folderPath = this.getFullFolderPath(this.selectedFile);
    const fullFilePath = folderPath ? `${folderPath}` : file.name;

    console.log('Download file:', file.name, 'from:', fullFilePath, 'in bucket:', bucketName);

    this.credentialsService
      .checkFileSize(companyId, workspaceId, cloudDefId, bucketName, fullFilePath)
      .subscribe({
        next: (response) => {
          // Call the service to get the download URL
          const downloadUrl = this.credentialsService.downloadFileUrl(
            companyId,
            workspaceId,
            cloudDefId,
            bucketName,
            fullFilePath
          );

          // Trigger the native browser download with an anchor tag
          const anchor = document.createElement('a');
          anchor.href = downloadUrl;
          anchor.target = '_blank'; // Optionally, open in a new tab/window
          anchor.download = file.name; // Set the file name for the download
          anchor.click(); // Trigger the download

          console.log(`Download started for file: ${file.name}`);
        },
        error: (error) => {
          if (error.status === 413) {
            console.error('File too large. Please use gcloud instead.');
            this.showDownloadSnippet('File too large. Please use gcloud instead.');
          } else {
            console.error('An error occurred:', error);
          }
        },
      });
  }

  showDownloadSnippet(optionalMessage: string | null): void {
    let credentials = this.credentialsService.getCurrentCredentials();
    console.log('Snippet knows about creds', credentials);
    if (credentials.provider === 'google') {
      const bucketName = this.selectedBucket!.data.name;
      if (!this.selectedFile || Array.isArray(this.selectedFile)) {
        console.error('No file selected or multiple files selected.');
        return;
      }
      const folderPath = this.getFullFolderPath(this.selectedFile);
      this.windowsSnippet = `gcloud auth login && gcloud storage cp gs://${this.selectedBucket!.data.name}/${folderPath} C:\\path\\to\\destination`;
      this.macSnippet = `gcloud auth login && gcloud storage cp gs://${this.selectedBucket!.data.name}/${folderPath} /path/to/destination`;
    }
    //TODO: Add snippets for other providers
    if (optionalMessage) {
      this.optionalMessage = optionalMessage;
    } else {
      this.optionalMessage = '';
    }
    this.snippetDialogVisible = true;
  }

  copyToClipboard(platform: string) {
    let snippetElement;

    if (platform === 'windows') {
      snippetElement = document.getElementById('windows-snippet');
    } else if (platform === 'mac') {
      snippetElement = document.getElementById('mac-snippet');
    }
  
    if (snippetElement) {
      const textToCopy = snippetElement.innerText; // Get the text from the code block
      navigator.clipboard.writeText(textToCopy).then(() => {
        console.log('Text copied to clipboard!');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      console.error('Snippet element not found');
    }
  }
  

  nodeSelect(event: any) {
    console.log('Node selected:', event);
    this.resetSelections();
    this.selectedFile = event.node;
    this.cdr.detectChanges(); // Trigger change detection to update the UI

    let cloud_defintion = this.findCloudProjectNode(event.node);
    const cloudDef = this.workspace.cloud_definitions.find(
      (def: any) => def.name === cloud_defintion.data.name
    );
    this.onCloudDefinitionSelected(cloudDef);

    this.selectedBucket = this.findBucketNode(event.node);
    let containingFolderNode = event.node.parent;
    if (event.node.data.type === 'file' && event.node.data.name.endsWith('.csv')) {
      let fullFolderPath = null;
      if (containingFolderNode.data.type === 'folder') {
        fullFolderPath = this.getFullFolderPath(containingFolderNode);
      }
      let projectPath = fullFolderPath
        ? `gs://${this.selectedBucket.data.name}/${fullFolderPath}`
        : `gs://${this.selectedBucket.data.name}/`;

      this.workflowService.metaSheetName = event.node.data.name;
      this.workflowService.projectFolderPath = projectPath;
      this.workflowService.selectedBucket = this.selectedBucket.data.name;

      this.checkRegionMismatch();

      console.log('Selected CSV:', this.workflowService.metaSheetName);
      console.log('In Folder:', this.workflowService.projectFolderPath);
      console.log('In Bucket:', this.workflowService.selectedBucket);
    } else {
      console.log('Selected node is not a downloadable file.');
    }
  }

  nodeUnselect(event: any) {
    console.log('Node unselected:', event);
  }

  onNodeExpand(event: any) {
    const node = event.node;
    if (node) {
      if (node.data.type === 'Cloud Project') {
        this.resetSelections();
        this.loadBuckets(node);
      } else if (node.data.type === 'Bucket') {
        this.loadFoldersAndFiles(node);
      } else if (node.data.type === 'folder') {
        console.log('You clicked to expand a foldr:', node);
        this.loadSubfoldersAndFiles(node);
      }
    }
  }

  loadBuckets(node: TreeNode) {
    const cloudDef = this.workspace.cloud_definitions.find((def: any) => def.name === node.data.name);

    if (cloudDef) {
      this.onCloudDefinitionSelected(cloudDef);
      this.credentialsService.get_cloud_buckets(this.companyId!, this.workspace.id, cloudDef.id).subscribe({
        next: (buckets) => {
          console.log('buckets:', buckets);
          node.children = buckets.map((bucket) => ({
            data: {
              name: bucket.name,
              location: bucket.region,
              type: 'Bucket',
            },
            children: [
              {
                data: {
                  name: 'Loading...',
                  type: 'loading',
                },
              },
            ],
          }));
          node.expanded = true;
          // Force the TreeTable to update
          const tempData = [...this.data];
          this.data = [];
          this.cdr.detectChanges();
          this.data = tempData;
        },
        error: (err) => {
          console.error('Error loading buckets:', err);
        },
      });
    }
  }

  loadFoldersAndFiles(node: TreeNode) {
    const cloudProjectNode = this.findCloudProjectNode(node);
    const cloudDef = this.workspace.cloud_definitions.find(
      (def: any) => def.name === cloudProjectNode.data.name
    );
    if (cloudDef) {
      let foldersLoaded = false;
      let filesLoaded = false;
      let folders: TreeNode[] = [];
      let files: TreeNode[] = [];
      const bucketName = node.data.name;
      const folderPath = ''; // Root level path

      this.credentialsService
        .get_cloud_folders(this.companyId!, this.workspace.id, cloudDef.id, bucketName, folderPath)
        .subscribe({
          next: (loadedFolders) => {
            folders = loadedFolders.map((folder) => ({
              data: {
                name: folder.name,
                type: 'folder',
              },
              children: [
                {
                  data: {
                    name: 'Loading...',
                    type: 'loading',
                  },
                },
              ],
            }));
            foldersLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
          error: (err) => {
            console.error('Error loading folders:', err);
            foldersLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
        });

      this.credentialsService
        .get_files_in_folder(this.companyId!, this.workspace.id, cloudDef.id, bucketName, folderPath)
        .subscribe({
          next: (loadedFiles) => {
            files = loadedFiles.map((file) => ({
              data: {
                name: file.name,
                type: 'file',
              },
            }));
            filesLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
          error: (err) => {
            console.error('Error loading files:', err);
            filesLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
        });
    }
  }

  loadSubfoldersAndFiles(node: TreeNode) {
    const cloudProjectNode = this.findCloudProjectNode(node);
    const cloudDef = this.workspace.cloud_definitions.find(
      (def: any) => def.name === cloudProjectNode.data.name
    );
    const bucketNode = this.findBucketNode(node);
    const bucketName = bucketNode.data.name;
    const folderPath = this.getFullFolderPath(node); // Get the full path of the current folder
    if (cloudDef) {
      console.log('Loading subfolders and files for:', cloudDef, bucketName, folderPath);
      let foldersLoaded = false;
      let filesLoaded = false;
      let folders: TreeNode[] = [];
      let files: TreeNode[] = [];

      this.credentialsService
        .get_cloud_folders(this.companyId!, this.workspace.id, cloudDef.id, bucketName, folderPath)
        .subscribe({
          next: (loadedFolders) => {
            console.log('Loaded folders:', loadedFolders);
            folders = loadedFolders.map((folder) => ({
              data: {
                name: folder.name,
                type: 'folder',
              },
              children: [
                {
                  data: {
                    name: 'Loading...',
                    type: 'loading',
                  },
                },
              ],
            }));
            foldersLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
          error: (err) => {
            console.error('Error loading subfolders:', err);
            foldersLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
        });

      this.credentialsService
        .get_files_in_folder(this.companyId!, this.workspace.id, cloudDef.id, bucketName, folderPath)
        .subscribe({
          next: (loadedFiles) => {
            files = loadedFiles.map((file) => ({
              data: {
                name: file.name,
                type: 'file',
              },
            }));
            filesLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
          error: (err) => {
            console.error('Error loading files:', err);
            filesLoaded = true;
            this.updateNodeChildren(node, folders, files, foldersLoaded, filesLoaded);
          },
        });
    }
  }

  getFullFolderPath(node: TreeNode): string {
    let path = node.data.name;
    let current = node.parent;
    while (current && current.data.type !== 'Bucket') {
      path = current.data.name + path;
      current = current.parent;
    }
    return path;
  }

  findCloudProjectNode(node: TreeNode): TreeNode {
    let currentNode = node;
    while (currentNode.parent && currentNode.data.type !== 'Cloud Project') {
      currentNode = currentNode.parent;
    }
    return currentNode;
  }

  findBucketNode(node: TreeNode): TreeNode {
    let currentNode = node;
    while (currentNode.parent && currentNode.data.type !== 'Bucket') {
      currentNode = currentNode.parent;
    }
    return currentNode;
  }

  updateNodeChildren(
    node: TreeNode,
    folders: TreeNode[],
    files: TreeNode[],
    foldersLoaded: boolean,
    filesLoaded: boolean
  ) {
    if (foldersLoaded && filesLoaded) {
      node.children = [...folders, ...files];
      // Force the TreeTable to update
      const tempData = [...this.data];
      this.data = [];
      this.cdr.detectChanges();
      this.data = tempData;
    }
  }
}
