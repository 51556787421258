<ng-container *ngIf="this.workflow_data">
  <biomodal-webapps-image-hero
    [bgImage]="'../assets/hero-3.png'"
    [title]="'Results'"
    [description]="'Visualize and download your genetic analysis results.'"
    [svgIcon]="this.workflow_data.pipeline_type == 'Duet_modC' ? '../assets/modc.svg' : '../assets/evoc.svg'"
  >
  </biomodal-webapps-image-hero>
</ng-container>

<div *ngIf="this.workflow_data" class="container mt-5">
  <div class="row g-3 mb-4">
    <h1>{{ this.workflow_data.run_name }}</h1>
  </div>
  <div class="row g-3 mb-4">
    <div class="align-items-center gap-2 flex-wrap" style="display: flex">
      <p-chip
        *ngFor="let param of key_run_params"
        [label]="param.label"
        [icon]="param.icon"
        [ngClass]="param.color"
      ></p-chip>
    </div>
  </div>
  <div class="row g-3 mb-4">
    <div class="col-md-3">
      <a
        href="https://biomodal.com/kb_articles/DataInterpretationGuide/"
        target="_blank"
        class="card h-100 bg-dark text-white text-decoration-none"
      >
        <img src="../../../assets/charts.jpg" class="card-img" alt="publication ready charts" />
        <div class="card-img-overlay d-flex flex-column justify-content-end">
          <h5 class="card-title">Data Interpretation Guide</h5>
          <p class="card-text">Our step-by-step data guide to help you navigate your data with ease</p>
        </div>
      </a>
    </div>
    <div class="col-md-9">
      <div class="card h-100">
        <div class="card-header text-center bg-primary text-light">Reports</div>

        <!-- Conditionally show the spinner if loading is true -->
        <div *ngIf="loadingHTMLReport" class="d-flex justify-content-center align-items-center" style="height: 200px">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span class="ms-2">Loading report...</span>
        </div>

        <!-- Conditionally show the list of reports if not loading -->
        <ul *ngIf="!loadingHTMLReport" class="list-group list-group-flush scrollable-list">
          <li *ngFor="let report of reports" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-truncate" style="max-width: 70%; font-size: 0.8rem">
                {{ report.display }}
              </div>
              <div class="btn-group" role="group">
                <button
                  type="button"
                  [disabled]="report.mimeType !== 'text/html'"
                  class="btn btn-outline-primary btn-sm"
                  (click)="viewReport(report)"
                >
                  <i class="bi bi-eye"></i>
                </button>
                <button type="button" class="btn btn-primary btn-sm" (click)="downloadReport(report)">
                  <i class="fas fa-download"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Grouped Listbox for Key Metrics -->
    <!-- Key Metrics Row -->
    <!-- Key Metrics Row -->
    <div class="row g-3 mb-4">
      <div class="col-md-12">
        <div class="card h-100">
          <div class="card-header text-center bg-primary text-light">Key Quality Metrics</div>
          <div class="card-body text-center">
            <p-multiSelect
              [options]="groupedMetrics"
              [group]="true"
              [(ngModel)]="selectedMetrics"
              (ngModelChange)="onSelectedMetricsChange()"
              placeholder="Select Metrics"
              scrollHeight="250px"
              [style]="{ width: '100%' }"
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <img
                    src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'mr-2 flag flag-' + group.value"
                    style="width: 20px"
                  />
                  <span>{{ group.label }}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <!-- Dynamic Metric Tiles -->
    <div class="row g-3 mb-4">
      <div class="col-md-6" *ngFor="let metric of selectedMetrics">
        <biomodal-webapps-generic-scatter
          [title]="metric.label"
          [description]="metric.description"
          [numFormat]="metric.numFormat"
          [data]="metric.data"
          [docLink]="metric.doc_link"
        >
        </biomodal-webapps-generic-scatter>
      </div>
    </div>
  </div>

  <!-- <div class="col-md-3">
      <div class="card h-100">
        <div class="card-header text-center bg-primary text-light">Cost</div>
        <div class="card-body text-center">
          <p class="card-text display-4 my-4">
            {{
              this.workflow_data.tower_workflow.progress.workflowProgress.cost
                | currency : 'USD' : 'symbol' : '1.2-2'
            }}
          </p>
          <p class="small text-muted">Estimated cost</p>
        </div>
      </div>
    </div> -->
</div>
