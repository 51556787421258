import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {
  AltoCompanyService,
  AltoWorkflowRequest,
  ComputeEnvService,
  CredentialsService,
  WorkflowService,
  WorkspaceService,
} from '@biomodal-webapps/alto-service';
import * as Constants from '@biomodal-webapps/alto-service/constants';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import {
  AccordionSectionComponent,
  AccordionWrapperComponent,
  FormlyHeaderedSectionWrapperComponent,
} from '@biomodal-webapps/ui-components';
import { Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'alto-duet-launch-select-params',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormlyModule, FormlyBootstrapModule, AccordionModule],
  templateUrl: './step-select-params.component.html',
  styleUrl: './step-select-params.component.css',
})
export class DuetLaunchSelectParamsComponent implements OnInit, OnDestroy {
  $destroy = new Subject<void>();
  selected_company: any = null;
  selected_workspace: any = null;
  selected_creds: any = null;
  selected_compute_env: any = null;

  isLaunching: boolean = false;
  isSuccess: boolean | null = null;
  errorMessage: string = '';

  sample_prefix_results = ['1', '2', '3'];
  samplePrefixResultsObservable = new BehaviorSubject<string>('');

  constructor(
    private altoCompanyService: AltoCompanyService,
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService,
    private computeService: ComputeEnvService,
    private workflowService: WorkflowService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  ngOnInit(): void {
    console.log('ParamsFormPage: project folder path', this.workflowService.projectFolderPath);

    this.altoCompanyService.currentCompany$.pipe(takeUntil(this.$destroy)).subscribe((company: any) => {
      console.log('Select Params Component: company:', company);
      //TODO: Redirect to homepage if company is null
      //or if it changes during the lifecycle of this component
      this.selected_company = company;
    });
    this.workspaceService.currentWorkspace$.pipe(takeUntil(this.$destroy)).subscribe((workspace: any) => {
      //TODO: Redirect to launcher homepage if workspace is null
      //or if it changes during the lifecycle of this component
      if (!workspace) {
        this.router.navigate(['/launch-duet/introduction']);
      }
      console.log('Select Params Component: workspace:', workspace);
      this.selected_workspace = workspace;
    });

    this.credentialsService.currentCredentials$.pipe(takeUntil(this.$destroy)).subscribe((creds: any) => {
      //TODO: Redirect to launcher homepage if workspace is null
      //or if it changes during the lifecycle of this component
      console.log('Select Params Component: creds:', creds);
      this.selected_creds = creds;
    });
  }

  generateRunName(): string {
    const adjective = Constants.adjectives[Math.floor(Math.random() * Constants.adjectives.length)];
    const term = Constants.terms[Math.floor(Math.random() * Constants.terms.length)];
    return `${adjective.toLowerCase()}_${term.toLowerCase()}`;
  }

  form = new FormGroup({});
  model = {
    //Main Options
    [Constants.NF_RUN_NAME]: this.generateRunName(),
    selectedPipeline: {
      pipelineId: null,
      versionId: null,
    },
    overrideVersion: '', // biomodal internal only override field
    [Constants.NF_REFERENCE_GENOME]: Constants.REFERENCE_GENOMES[0].value,
    numReads: Constants.NF_RESOURCE_PROFILE_SELECTION_OPTIONS[0].value,

    //ADVANCED OPTIONS
    //Epigenomic Quantification
    epigenomic_quantification: {
      [Constants.NF_CGH_CHH_CONTEXTS]: false,
    },

    //Variant Calling and Allele Specific Methylation
    vc_and_asm: {
      [Constants.NF_CALL_SOMATIC_VARIANTS]: false,
      [Constants.NF_CALL_GERMLINE_VARIANTS]: true,
      [Constants.NF_USE_GVCF]: false,
      [Constants.NF_COMPUTE_ASM]: false,
    },
    //Targeted Sequencing
    targeted_sequencing: {
      [Constants.NF_TARGETED]: false,
      [Constants.NF_TARGET_PANEL]: Constants.NF_TARGET_PANEL_DEFAULT,
    },

    //Unique Molecular Indices
    unique_molecular_indices: {
      [Constants.NF_UMI]: false,
    },

    //Other Settings
    other_settings: {
      [Constants.NF_SUBSAMPLE]: '',
      [Constants.NF_LIB_PREFIX]: '',
      [Constants.NF_RUN_INTERNAL]: false,
    },
  };
  fields: FormlyFieldConfig[] = [
    //Main Options
    {
      key: Constants.NF_RUN_NAME,
      type: 'input',
      props: {
        label: 'Run Name',
        placeholder: 'Enter a unique name for this run',
        required: true,
      },
      validators: {
        noSpecialChars: {
          expression: (c: any) => !c.value || /^[a-zA-Z0-9_]*$/.test(c.value),
          message: (error: any, field: any) =>
            `The "${field.formControl.value}" cannot contain spaces or special characters.`,
        },
        doesNotStartWithNumber: {
          expression: (c: any) => !c.value || !/^[0-9].*/.test(c.value),
          message: () => `The name cannot start with a number.`,
        },
      },
    },
    {
      key: 'selectedPipeline',
      type: 'tile-select',
      props: {
        label: 'Pipeline',
        placeholder: 'Select a pipeline',
        required: true,
        options: [],
      },
      hooks: {
        onInit: (field) => {
          field.props!.options = this.selected_company?.pipelines
            .filter((pipeline: any) => !!pipeline.type)
            .map((pipeline: any) => ({
              label: pipeline.name,
              value: pipeline.id,
              type: pipeline.type,
              versions: pipeline.versions.map((v: any) => ({ label: v.version, id: v.id })), // Adjusted to match nested structure
            }));
        },
      },
    },
    // {
    //   key: 'overrideVersion',
    //   type: 'input',
    //   props: {
    //     label: 'Override Pipeline Version',
    //     placeholder: 'Enter a githash or custom version ID',
    //     required: false,
    //     description: 'Only for Biomodal internal use',
    //   },
    //   //TODO: hideExpression: '!this.selected_company || this.selected_company.name !== "biomodal"',
    // },
    // {
    //   key: 'selectedPipelineVersion',
    //   type: 'select',
    //   props: {
    //     label: 'Pipeline Version',
    //     placeholder: 'Select a version',
    //     options: [],
    //   },
    //   hideExpression: '!model.selectedPipeline', // Hide this field until a pipeline is selected
    //   hooks: {
    //     onInit: (field) => {
    //       // Access the parent form to get the 'selectedPipeline' control
    //       const selectedPipelineControl = field.parent!.form!.get('selectedPipeline');
    //       selectedPipelineControl!.valueChanges
    //         .pipe(
    //           takeUntil(this.$destroy),
    //           // Diagnostic logging
    //           tap((selectedPipelineId) => console.log('Selected pipeline id', selectedPipelineId))
    //         )
    //         .subscribe((selectedPipelineId) => {
    //           // Find the selected pipeline based on the type
    //           const selectedPipeline = this.selected_company?.pipelines.find(
    //             (pipeline: any) => pipeline.id === selectedPipelineId
    //           );
    //           console.log('Selected pipeline:', selectedPipeline);
    //           // Update the version options based on the selected pipeline
    //           field.props!.options =
    //             selectedPipeline?.versions.map((version: any) => ({
    //               label: version.version,
    //               value: version.id,
    //             })) || [];
    //           // Reset the selected version when the pipeline changes
    //           field.formControl!.setValue(null);
    //         });
    //     },
    //   },
    // },
    // {
    //   key: 'referenceGenome',
    //   type: 'select',
    //   props: {
    //     label: 'Reference Genome',
    //     placeholder: 'Select a reference genome',
    //     required: true,
    //     options: Constants.REFERENCE_GENOMES.map((option) => ({
    //       label: option.display,
    //       value: option.value,
    //     })),
    //   },
    // },
    {
      key: 'referenceGenome',
      type: 'ref-genome-select',
      props: {
        label: 'Reference Genome',
        placeholder: 'Select a reference genome',
        required: true,
        options: Constants.REFERENCE_GENOMES.map((option) => ({
          label: option.display,
          value: option.value,
        })),
      },
    },
    {
      key: 'numReads',
      type: 'select',
      props: {
        label: 'Max number of reads per sample per lane',
        placeholder: 'Select a number of reads per sample per lane',
        required: true,
        options: Constants.NF_RESOURCE_PROFILE_SELECTION_OPTIONS.map((option) => ({
          label: option.display,
          value: option.value,
        })),
      },
    },

    //ADVANCED OPTIONS

    {
      key: 'advanced_options',
      wrappers: [AccordionSectionComponent],
      props: { label: 'Advanced Options' },
      fieldGroup: [
        //Epigenomic Quantification
        {
          key: 'epigenomic_quantification',
          wrappers: [FormlyHeaderedSectionWrapperComponent],
          props: { label: 'Epigenomic Quantification' },
          fieldGroup: [
            {
              key: Constants.NF_CGH_CHH_CONTEXTS,
              type: 'toggle',
              defaultValue: false,
              props: {
                label: 'CHG and CHH modification calling',
              },
            },
          ],
        },

        //Variant Calling and Allele Specific Methylation
        {
          key: 'vc_and_asm',
          wrappers: [FormlyHeaderedSectionWrapperComponent],
          props: { label: 'Variant Calling and Allele Specific Methylation' },
          fieldGroup: [
            {
              key: Constants.NF_CALL_SOMATIC_VARIANTS,
              type: 'toggle',
              props: {
                label: 'Call Somatic Variants',
              },
              defaultValue: false,
            },
            {
              key: Constants.NF_CALL_GERMLINE_VARIANTS,
              type: 'toggle',
              props: {
                label: 'Call Germline Variants',
              },
              defaultValue: true,
            },
            {
              key: Constants.NF_USE_GVCF,
              type: 'toggle',
              props: {
                label: 'Joint Variant Calling',
              },
              defaultValue: false,
              expressionProperties: {
                // Disable based on 'callGermlineVariants'
                'props.disabled': `!model.${Constants.NF_CALL_GERMLINE_VARIANTS}`,
                // Reset value to false when disabled
                [`model.${Constants.NF_USE_GVCF}`]: `!model.${Constants.NF_CALL_GERMLINE_VARIANTS} ? false : model.${Constants.NF_USE_GVCF}`,
              },
            },
            {
              key: Constants.NF_COMPUTE_ASM,
              type: 'toggle',
              props: {
                label: 'Compute ASM',
              },
              defaultValue: false,
              expressionProperties: {
                // Disable based on 'jointVariantCalling' and 'callGermlineVariants'
                'props.disabled': `!model.${Constants.NF_USE_GVCF} || !model.${Constants.NF_CALL_GERMLINE_VARIANTS}`,
                // Reset value to false when disabled
                [`model.${Constants.NF_COMPUTE_ASM}`]: `(!model.${Constants.NF_USE_GVCF} || !model.${Constants.NF_CALL_GERMLINE_VARIANTS}) ? false : model.${Constants.NF_COMPUTE_ASM}`,
              },
            },
          ],
        },

        //Targeted Sequencing
        {
          key: 'targeted_sequencing',
          wrappers: [FormlyHeaderedSectionWrapperComponent],
          props: { label: 'Targeted Sequencing' },
          fieldGroup: [
            {
              key: Constants.NF_TARGETED,
              type: 'toggle',
              props: {
                label: 'Targeted analysis',
                description: 'By default (false), no targeted metrics are calculated',
              },
              defaultValue: false,
            },
            {
              key: Constants.NF_TARGET_PANEL,
              type: 'select',
              props: {
                label: 'Targeted Panel',
                description: 'Specifies which targeted panel to use',
                options: Constants.NF_TARGET_PANEL_OPTIONS.map((option) => ({
                  label: option,
                  value: option,
                })),
              },
              defaultValue: Constants.NF_TARGET_PANEL_DEFAULT,
              hideExpression: `!model.${Constants.NF_TARGETED}`,
              expressionProperties: {
                // This property ensures the field is only enableable/visible if `targeted` is true.
                // Additional logic based on `use_gvcf` or other conditions can be added similarly.
                'props.disabled': `!model.${Constants.NF_TARGETED}`,
              },
            },
          ],
        },

        //Unique Molecular Indices
        {
          key: 'unique_molecular_indices',
          wrappers: [FormlyHeaderedSectionWrapperComponent],
          props: { label: 'Unique Molecular Indices' },
          fieldGroup: [
            {
              key: Constants.NF_UMI,
              type: 'toggle',
              props: {
                label: 'Use UMIs for deduplication?',
              },
              defaultValue: false,
            },
          ],
        },

        //Other Settings
        {
          key: 'other_settings',
          wrappers: [FormlyHeaderedSectionWrapperComponent],
          props: { label: 'Other Settings' },
          fieldGroup: [
            {
              key: Constants.NF_SUBSAMPLE,
              type: 'input',
              props: {
                label: 'Subsampling',
                placeholder:
                  'Subsample the reads to processing, indicating the maximum number of reads per sample per lane',
                required: false,
              },
            },
            {
              key: Constants.NF_LIB_PREFIX,
              type: 'input',
              props: {
                label: 'Sample Prefix',
                placeholder: 'Only process samples with specific filename prefix',
                required: false,
              },
              validators: {
                samplePrefixValidator: {
                  expression: (c: AbstractControl) => {
                    // Assuming `this.sample_prefix_results` is accessible in this scope
                    return !c.value || (this.sample_prefix_results && this.sample_prefix_results.length > 0);
                  },
                  message: (error: any, field: FormlyFieldConfig) =>
                    `No matching samples found for "${field.formControl!.value}".`,
                },
              },
              validation: {
                messages: {
                  prefixValidation: 'No matching samples found or field is empty.',
                },
              },
              hooks: {
                onInit: (field) => {
                  const formControl = field.formControl;
                  if (!formControl) return;

                  // Create a subject to debounce the input
                  const debounceSubject = new Subject<any>();

                  debounceSubject
                    .pipe(
                      debounceTime(1000), // Wait for 1 second after the last keystroke
                      distinctUntilChanged() // Only emit if the current value is different from the last
                    )
                    .subscribe(() => {
                      // Placeholder for validation logic
                      this.credentialsService
                        .validate_sample_prefix(
                          this.selected_company.id,
                          this.selected_workspace.id,
                          this.selected_creds.id,
                          this.workflowService.selectedBucket!,
                          this.workflowService.projectFolderPath + 'nf-input/' + formControl.value
                        )
                        .subscribe((response) => {
                          console.log('Sample prefix validation response:', response);
                          // Placeholder for handling the response
                          this.sample_prefix_results = response;
                          const newTemplate = `
                  <div class="alert alert-success" role="alert">
                    <div>
                      The following files will be provided as input:
                    </div>
                    <ul class="small">${this.sample_prefix_results
                      .map((result) => `<li>${result}</li>`)
                      .join('')}</ul>

                  </div>
                  `;

                          this.samplePrefixResultsObservable.next(newTemplate);
                          console.log(
                            'Sample prefix results: Check form validity:',
                            this.sample_prefix_results
                          );
                          formControl.updateValueAndValidity(); // Trigger validation check
                        });
                      console.log(`Value after debounce: ${formControl.value}`);
                    });

                  // Subscribe to the FormControl value changes
                  formControl.valueChanges.subscribe((value) => {
                    debounceSubject.next(value);
                  });
                },
              },
            },

            {
              key: 'dynamicContent',
              type: 'customTemplate',
              templateOptions: {
                template: '<div>Initial Template</div>',
                updateTemplate: this.samplePrefixResultsObservable, // Observable that emits the new template string
              },
            },

            {
              key: Constants.NF_RUN_INTERNAL,
              type: 'toggle',
              props: {
                label: 'Run Internal Analysis',
              },
              defaultValue: false,
            },
          ],
        },
      ],
    },
  ];

  onSubmit(model: any) {
    console.log(model);
    this.isLaunching = true;
    this.isSuccess = null;

    let companyId = this.selected_company?.id;
    console.log('Selected company:', companyId);

    let workspaceId = this.selected_workspace?.id;
    console.log('Selected workspace:', workspaceId);

    let credentialsId = this.selected_creds?.id;
    console.log('Selected credentials:', credentialsId);

    let computeEnv = this.computeService.getCurrentCompute();
    console.log('Selected compute env:', computeEnv!.id);

    let dynamicKeys: { [key: string]: any } = {};

    // We use the same keys in both the model and `pipeline_params` to ensure consistency.
    // To match the pipeline's expected capitalization and spelling exactly, we store these keys in a constants file.
    // This way, by iterating over the model's groups, we dynamically assign these keys and their values to `pipeline_params`,
    // ensuring our payload matches the API specifications precisely without hardcoding the keys multiple times.

    const groups = [
      'epigenomic_quantification',
      'vc_and_asm',
      'targeted_sequencing',
      'unique_molecular_indices',
      'other_settings',
    ];

    dynamicKeys[Constants.NF_RUN_NAME] = model[Constants.NF_RUN_NAME];
    dynamicKeys[Constants.NF_TAG] = model[Constants.NF_RUN_NAME] + '_' + new Date().toISOString();
    (dynamicKeys[Constants.NF_META_FILE] = this.joinPaths(
      this.workflowService.projectFolderPath!,
      this.workflowService.metaSheetName!
    )),
      (dynamicKeys[Constants.NF_REFERENCE_PATH] = Constants.DEFAULT_REFERENCE_PATH);
    dynamicKeys[Constants.NF_REFERENCE_GENOME] = model[Constants.NF_REFERENCE_GENOME];
    (dynamicKeys[Constants.NF_DATA_PATH] = this.workflowService.projectFolderPath!),
      groups.forEach((group) => {
        // Accessing each group in the model
        const groupFields = model.advanced_options[group];
        Object.keys(groupFields).forEach((fieldKey) => {
          // fieldKey here is actually a constant from the Constants object
          // e.g., Constants.NF_CGH_CHH_CONTEXTS, which is a string
          // The value for each field is directly assigned
          dynamicKeys[fieldKey] = groupFields[fieldKey];
        });
      });


      let pipelineVersionId = model.selectedPipeline.versionId;

      // Override pipeline version if the override field is populated and the company is 'biomodal'
      if (model.overrideVersion) {
        console.log('Overriding pipeline version with:', model.overrideVersion);
        pipelineVersionId = model.overrideVersion;
      }

    let workflowRequest: AltoWorkflowRequest = {
      meta_data: {
        cloudDefinitionId: credentialsId,
        computeEnvId: computeEnv!.id,
        workDir: computeEnv!.workdir,
        pipeline_type_id: model.selectedPipeline.pipelineId,
        pipeline_version_id: pipelineVersionId,
        num_reads: model.numReads,
        resume: false,
        pipeline_params: dynamicKeys,
      },
    };

    console.log('Workflow request:', workflowRequest);

    this.workflowService.launchWorkflow(companyId, workspaceId, credentialsId, workflowRequest).subscribe({
      next: (response) => {
        this.isLaunching = false;
        this.isSuccess = true;
        console.log('Workflow launch response:', response);
      },
      error: (error) => {
        this.isLaunching = false;
        this.isSuccess = false;
        this.errorMessage = error.error?.message || 'An unexpected error occurred. Please try again.';
        console.error('Workflow launch error:', error);
      },
    });
  }

  joinPaths(...paths: string[]): string {
    console.log('Original paths:', paths);

    // Handle the URL scheme in the first path segment, if present.
    let firstPath = paths[0];
    const urlSchemeMatch = firstPath.match(/^[a-z][a-z0-9+.-]*:/i);
    if (urlSchemeMatch) {
      firstPath = firstPath.replace(urlSchemeMatch[0], urlSchemeMatch[0].replace(/\/+/g, '//'));
    }

    console.log('Corrected first path:', firstPath);

    // Join all path segments using a single forward slash.
    let fullPath = [firstPath, ...paths.slice(1)].join('/');
    console.log('Joined path:', fullPath);

    // Extract the URL scheme and path separately.
    const splitPath = fullPath.split('://');
    if (splitPath.length > 1) {
      // If a URL scheme is found, clean the path part only.
      fullPath = splitPath[0] + '://' + splitPath[1].replace(/\/+/g, '/');
    } else {
      // If no URL scheme is found, clean the entire path.
      fullPath = fullPath.replace(/\/+/g, '/');
    }

    console.log('Cleaned path:', fullPath);

    return fullPath;
  }
}
