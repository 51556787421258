<h2>Metasheet and Files</h2>
<p style="margin-bottom: 25px">
  <strong>CSV metasheet selection</strong>: Below are your cloud projects, buckets and folder. Please navigate to the metasheet you wish to use for this run. It must be in the
  same folder/bucket as the 'nf-input' folder which contains your fastq files. Please view <a href="https://biomodal.com/kb_articles/Usage/#sequencing-run-metadata-file" target="_blank" style="color: #9cdbd9">this document</a> for more information about the metasheet requirements.
</p>
<div class="row g-3 mb-4">
  <div class="col-12">
    <p-treeTable
      [value]="data"
      [columns]="cols"
      [scrollable]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      selectionMode="single"
      [(selection)]="selectedFile"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
      (onNodeExpand)="onNodeExpand($event)"
    >
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-xl font-bold">Your Cloud Projects</div>
        <div class="btn-group">
          <!-- Download Button -->
          <button
            pButton
            icon="pi pi-download"
            class="p-button-primary"
            (click)="downloadFile()"
            [disabled]="!isDownloadableFile()"
            tooltip="Download Selected File"
            tooltipPosition="top"
          ></button>
    
          <!-- Script Snippet Button -->
          <button
            pButton
            icon="pi pi-code"
            class="p-button-secondary"
            (click)="showDownloadSnippet(null)"
            [disabled]="!isDownloadableFile()"
            tooltip="Show Download Script"
            tooltipPosition="top"
          ></button>
        </div>
      </div>
    </ng-template>
    
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            <ng-container>
              <img *ngIf="rowData.type === 'Cloud Project'" src="assets/icons8-cloud.svg" alt="Cloud Project" class="icon" />
              <img *ngIf="rowData.type === 'Bucket'" src="assets/icons8-box.svg" alt="Bucket" class="icon" />
              <img *ngIf="rowData.type === 'folder'" src="assets/icons8-folder.svg" alt="Folder" class="icon" />
              <img *ngIf="rowData.type === 'file'" src="assets/icons8-file.svg" alt="File" class="icon" />
              <i *ngIf="rowData.type === 'loading'" class="pi pi-spinner pi-spin"></i>

            </ng-container>
            {{ rowData.name }}
            <ng-container *ngIf="rowData.type === 'Bucket'">
              <span style="font-style: italic; font-size:smaller"> ({{ rowData.location }})</span>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary">
        <div style="text-align: left">
          <p-button icon="pi pi-refresh" label="Reload" severity="warning"></p-button>
        </div>
      </ng-template> -->
    </p-treeTable>
  </div>
</div>
<hr />
<div *ngIf="cloudDefId" style="margin-bottom: 20px">
  <strong style="color: white">Select Compute Resource</strong>
  <p style="margin-bottom: 25px">
    Select which compute environment you wish to use for this run.
    <span pTooltip="The compute environment is where your analysis will be run. Think of it as the computer doing the work." tooltipPosition="right">
      <i class="pi pi-info-circle"></i>
    </span>
  </p>
  <biomodal-webapps-compute-environments-list [showText]="false"></biomodal-webapps-compute-environments-list>
</div>
<hr />
<div style="text-align: right">
  <button
    class="progress-button"
    [routerLink]="['/launch-duet/select-params']"
    [disabled]="!validSelection()"
    pTooltip="Please select a CSV file and compute resource to proceed."
    tooltipPosition="top"
  >
    Next
  </button>
</div>
<div *ngIf="validationMessages.length > 0">
  <p-messages [(value)]="validationMessages" [enableService]="false"></p-messages>
</div>
<p-messages 
[(value)]="messages" 
[enableService]="false" 
[closable]="false" />

<p-dialog header="Download File with gcloud" [modal]="true" [(visible)]="snippetDialogVisible" [style]="{ width: '50rem' }">
  <div *ngIf="this.optionalMessage" class="alert alert-warning" role="alert">
    {{this.optionalMessage}}
  </div>
  <p class="text-dark">To download the file from Google Cloud Storage, use the following command depending on your operating system:</p>

  <!-- Windows Section -->
  <div class="mb-4">
    <h3 class="text-dark">Windows (CMD)</h3>
    <pre class="code-block"><code id="windows-snippet">{{this.windowsSnippet}}</code></pre>
    <button class="btn btn-secondary" (click)="copyToClipboard('windows')">
      <i class="bi bi-clipboard"></i> Copy to Clipboard
    </button>  </div>

  <!-- Mac/Linux Section -->
  <div class="mb-4">
    <h3 class="text-dark">Mac/Linux</h3>
    <pre class="code-block"><code id="mac-snippet">{{this.macSnippet}}</code></pre>
    <button class="btn btn-secondary" (click)="copyToClipboard('mac')">
      <i class="bi bi-clipboard"></i> Copy to Clipboard
    </button>  </div>
</p-dialog>


