import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'biomodal-webapps-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent {

  constructor(private router: Router) {}

  get isAtRoot(): boolean {
    //return this.router.url === '/';
    return false
  }

}
