<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<footer
    *ngIf="!isAtRoot"
    class="container-fluid p-5 text-white"
    style="background-color: var(--secondary); color: var(--307265a)"
>
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-3">
                <img
                    src="/assets/white-logo.png"
                    alt="Logo"
                    class="img-fluid"
                    style="max-height: 48px"
                />
            </div>
            <div class="col-lg-3">
                <h5 class="font-weight-bold">Product</h5>
                <ul class="list-unstyled">
                    <li>Technology</li>
                    <li>Product</li>
                    <li>Portal</li>
                </ul>
            </div>
            <div class="col-lg-3">
                <h5 class="font-weight-bold">Company</h5>
                <ul class="list-unstyled">
                    <li>About</li>
                    <li>News</li>
                    <li>Careers</li>
                    <li>Contact</li>
                </ul>
            </div>
            <div class="col-lg-3">
                <h5 class="font-weight-bold">Legal</h5>
                <ul class="list-unstyled">
                    <li>Terms of Use</li>
                    <li>Privacy Policy</li>
                    <li>Cookie Policy</li>
                    <li>Trademarks</li>
                </ul>
            </div>
        </div>
        <hr class="mb-4" />
        <div class="row">
            <div class="col-lg-6">
                <p>© 2023 biomodal. All rights reserved.</p>
            </div>
            <div class="col-lg-6" style="text-align: end; color: white">
                <a href="https://www.linkedin.com/company/biomoda"
                    ><i
                        class="fab fa-linkedin-in"
                        style="color: white; margin-right: 10px"
                    ></i
                ></a>
                <a href="https://twitter.com/biomodalhq"
                    ><i class="fab fa-twitter" style="color: white"></i
                ></a>
            </div>
        </div>
    </div>
</footer>
<footer *ngIf="isAtRoot" class="container-fluid p-0 pb-2 text-white streamlined-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <p>
                    © 2023 <a href="https://biomodal.com">biomodal</a>. All rights
                    reserved.
                </p>
            </div>
            <div class="col-lg-6" style="text-align: end; color: white">
                <a href="https://www.linkedin.com/company/biomoda"
                    ><i
                        class="fab fa-linkedin-in"
                        style="color: white; margin-right: 10px"
                    ></i
                ></a>
                <a href="https://twitter.com/biomodalhq"
                    ><i class="fab fa-twitter" style="color: white"></i
                ></a>
            </div>
        </div>
    </div>
</footer>
